export const Msg = {
  COMPANY_TITLE: "Grow your business with the most flexible B2B platform!",
  COMPANY_SLOGAN: "A place where you can find ",
  COMPANY_SLOGAN2: "the best deals",
  ACCOUNT_CREATE: "Don't have an account yet? ",
  ACCOUNT_CREATE_LINK: "Create an account",
  ACCOUNT_CREATED_MESSAGE: "Your Account is Created Successfully.",
  ACCOUNT_VERIFY_MESSAGE:
    "Please verify your account in mail with Invitation link.",
  AUTH_TOKEN: "ot-token",
  FORGOT_PASSWORD: "Forgot password?",
  HEADER_MESSAGE: "Welcome To Order Tank",
  CATEGORY_LIST_HEADER: "Category List",
  PRODUCT_LIST_HEADER: "Product List",
  BUYERS_LIST_HEADER: "Buyer List",
  TOTAL_CATEGORIES_FOUND: "Categories on Screen",
  TOTAL_ADDRESS_FOUND: "Addresses on Screen",
  FOUND: "Found",
  TOTAL_PRODUCTS_FOUND: "Products on Screen",
  TOTAL_BUYERS_FOUND: "Buyers on Screen",
  BUYER_ADD_HEADER:
    "Buyer will receive an invite to join your distribution network and will be able to order.",
  PROFILE_DETAILS: "Profile Details",
  OVERVIEW_TITLE: "Overview",
  BUYER_TITLE: "Buyer",
  BUYER_ADDED_SUCCESSFULLY: " Buyer Added Successfully!",
  BUYER_REQ_HEADER: "Total Requests",
  TOTAL_BUYER_REQ_FOUND: "Buyer Requests on Screen",
  BUYER_DETAILS_HEADING: "Buyers Detail",
  MOBILE_ALLOW_CHECK_CHECKBOX: "Allow them to order from Mobile App",
  INVENTORY_CHECK_CHECKBOX: "Can view Inventory",
  SELECT_CUSTOM_RANGE: "Select Date Range",
  COMPANY_DETAILS: "Company Details",
  MY_PROFILE: "My Profile",
  RESET_PASSWORD: "Reset Password",
  FORGOT_PASSWORD_MSG_1:
    "Enter the email address associated with your account ",
  FORGOT_PASSWORD_MSG_2: "and we’ll send you a link to reset your password.",
  ACCESS_DENIED_MSG: "Buyers are not allowed to use the seller platform.",
  FORGOT_EMAIL_SENT_MSG: "We sent you an email with instructions to reset your password",
  ONLY_CSV: "Only csv file are allowed",
  AVAILABLE_MSG: "Are You Sure You Want To Mark As",
  Download_CATEGORY_SAMPLE_TEMPLATE: "Download category sample template",
  Download_PRODUCT_SAMPLE_TEMPLATE: "Download product sample template",
  PLEASE_VERIFY: "Please verify email...",
  PLEASE_USE_MOBILE_APP: "Please use mobile application",
  LOGOUT_MENU: "Logout",

  // Required
  EMAIL: "Email is Required ",
  PASSWORD: "Password is Required ",
  CONFIRM_PASSWORD_REQ: "Confirm Password is Required",
  PHONE: "Phone No is Required Field !",
  NAME: "Name is Required Field !",
  COMPANY_LOGO_REQ: "Company Logo is Required !",
  COMPANY_NAME_REQ: "Company Name is Required",
  COMPANY_ADDRESS: "Address is Required",
  COMPANY_ADDRESS_NAME: "Address Name is Required",
  COMPANY_ADDRESS_LINE: "Address Line is Required",
  LOCALITY_REQ: "Locality is Required",
  PINCODE_REQ: "Pincode is Required",
  CITY_REQ: "City is Required",
  STATE_REQ: "State is Required",
  COUNTRY_REQ: "Country is Required",
  REGISTER_NO_REQ: "Register No is Required Field !",
  LOGO_UPLOAD_TITLE: "Upload Company logo",
  LOGO_UPDATE_TITLE: "Update Company logo",
  CATEGORY_NAME: "Category Name is Required Field !",
  CATEGORY_CODE: "Category Code is Required Field !",
  PRODUCT_NAME_REQ: "Product Name is Required Field !",
  CATEGORY_REQ: "Category is Required Field !",
  UNIT_REQ: "Unit is Required Field !",
  DESCRIPTION_REQ: "Description is Required Field !",
  PRICE_REQ: "Price is Required Field !",
  SKU_REQ: "Sku is Required Field !",
  PRODUCT_IMG: "Product Image is Required!",
  PRODUCT_IMG_UPLOAD_TITLE: "Upload Product",
  PRODUCT_IMG_UPDATE_TITLE: "Update Product",
  GST_NO_REQ: "GST/VAT Number is Required Field !",
  OWNER_NAME_REQ: "Owner/Reprentative Name is Required Field !",
  MOBILE_NO_REQ: "Mobile No is Required Field !",
  LOCATION_REQ: "Location is Required Field !",
  BUYER_COMPANY_REQ: "Buyer Company is required",
  PRODUCT_REQ: "Product is required",
  NOTES_REQ: "Notes is Required",
  DELIVERY_ADDRESS_REQ: "Delivery Address is Required",
  BILLING_ADDRESS_REQ: "Billing Address is Required",
  APPROX_DELIVERY_DATE_REQ: "Approx Delivery Date is required",
  OLD_PASSWORD_REQ: "Old  is Required",
  NEW_PASSWORD_REQ: "New Password is Required",
  STATUS_REQ: "Order Status is Required",
  ORDER_STATUS: "Order Status",
  ORDER_DATE: "Order Date",
  SCHEDULED_DATE: "Scheduled Date",
  SHIPPING_CHARGE: "Shipping Charges",
  UPLOAD_LOGO: "Upload Logo",
  UPDATELOGO: "Update Logo",
  GST_REQ: "GST No is Required Field !",
  PAN_REQ: "PAN No is Required Field !",
  UPLOAD_IMAGE: "Upload Image",
  UPDATE_IMAGE: "Update Image",

  //Invalid
  INVALID_EMAIL: "Invalid email address",
  PASSWORD_CHAR: "The password must be at least 6 characters long.",
  INVALID_PHONE: "Phone number is not valid",
  PASSWORD_MAX_LENGTH: "Password should be at most 10 characters long.",
  PASSWORD_MIN_LENGTH: "The password must be at least 6 characters long.",
  EMAIL_NOT_VARIFIED: "Verify Your Email Address",
  RESEND_EMAIL:
    "Please check your email for a verification link. If you did not recieve the email. We can Resend it.",
  INVALID_PINCODE: "Invalid Pincode",
  INVALID_LOGO_SIZE: "Uploaded Logo is Too Big",
  PASSWORD_NOT_MATCH: "Passwords does not match",
  CONFIRM_PASSWORD_NOT_MATCH: " Confirm Password does not match",
  NEW_PASSWORD_ERR: "New password must be different from the old password",
  INVALID_IMAGE: "File size must be less than 5 mb",
  FILE_NOT_COMPRESS: "This file is not valid for compress",
  INVALID_PAN: " Invalid PAN Number",
  INVALID_GST: " Invalid GST Number",

  //Error
  ERR: "Something went wrong",
  NOT_FOUND: "Not Found...",

  //Placeholders
  USERNAME: "Enter Your Name",
  PHONE_NO: "Enter Your Phone No",
  EMAIL_ADDRESS: "Enter Your Email",
  EMAIL_INVITE_ADDRESS: "Enter  Email",
  _PASSWORD: "Enter Your Password",
  _CONFIRM_PASSWORD: "Enter Your Confirm Password",
  COMPANY_NAME: "Enter Company Name",
  REGISTER_NO: "Enter Register No",
  ADDRESS: "Enter Address",
  ADDRESS_NAME: "Enter Address Name",
  ADDRESS_LINE: "Enter Address LINE",
  LOCALITY: "Enter Locality",
  PINCODE: "Enter Pincode",
  CITY: "Enter City",
  STATE: "Select State",
  COUNTRY: "Select Country",
  CATEGORY: "Enter Category",
  ENTER_CATEGORY_CODE: "Enter Category Code",
  SEARCH: "Search...",
  PRODUCT_NAME: "Enter Product Name",
  UNIT: "Enter Unit",
  MIN_UNIT: "Enter Min Quantity",
  MAX_UNIT: "Enter Max Quantity",
  PRICE: "Enter Price",
  SKU: "Enter Sku",
  EMAIL_ADD: "Enter Email",
  GST_NO: "Enter GST/VAT Number",
  STATUS: "Select Status",
  OLD_PASSWORD: "Enter Old Password",
  NEW_PASSWORD: "Enter New Password",
  CONFIRM_NEW_PASSWORD: "Enter Confirm Password",
  GST_NUMBER: "Enter GST No",
  PAN_NO: "Enter PAN No",

  // Label
  NAME_lABEL: "Name",
  PHONE_LABEL: "Phone No",
  EMAIL_LABEL: "Email",
  PASSWORD_LABEL: "Password",
  CONFIRM_PASSWORD: "Confirm Password",
  CONFIRM_NEW_PASSWORD_LABEL: "Confirm New Password",
  LOGIN: "Login",
  ADD_COMPANY: "Add Company",
  LOGO: "Company Logo",
  COMPANY_NAME_LABEL: "Company Name",
  ADDRESS_LABEL: "Address",
  LOCALITY_LABEL: "Locality",
  PINCODE_LABEL: "Pincode",
  CITY_LABEL: "City",
  STATE_LABEL: "State",
  COUNTRY_LABEL: "Country",
  REGISTER_NO_LABEL: "Register No",
  ADD_CATEGORY_LABEL: "Add Category",
  ADD_PRODUCT_LABEL: "Add Product",
  UPDATE_PRODUCT_LABEL: "Update Product",
  UPDATE_CATEGORY_LABEL: "Update Category",
  CATEGORY_DELETE_LABEL: "Are you sure you want to delete this Category?",
  CATEGORY_NAME_LABEL: "Category Name",
  CATEGORY_CODE_LABEL: "Category Code",
  SELECT_CATEGORY_LABEL: "Select Category",
  BUYER_STATUS: "Buyer Status",
  UNIT_LABEL: "Unit",
  MIN_QUT_LABEL: "Min Quantity",
  MAX_QUT_LABEL: "Max Quantity",
  DESCRIPTION_LABEL: "Description",
  PRICE_LABEL: "Price",
  IS_AVAILABLE_LABEL: "is Available",
  IMAGE_LABEL: "Image",
  SKU_LABEL: "SKU",
  PRODUCT_DELETE_LABEL: "Are you sure you want to delete this Product?",
  ALL_PRODUCT_DELETE_LABEL: "Are you sure you want to delete this Products?",
  PRODUCT_NAME_LABEL: "Name",
  PRODUCT_CATEGORY_LABEL: "Category",
  PRODUCT_UNIT_LABEL: "Unit",
  PRODUCT_DESC_LABEL: "Description",
  PRODUCT_AVAILABLE: "Available",
  BUYER_ADD_LABEL: "Add Buyer",
  BUYER_UPDATE_LABEL: "Edit Buyer",
  BUYER_ADD_COMPANY_NAME: "Company/Shop Name",
  BUYER_ADD_GST_NO: "GST/VAT Number",
  BUYER_DELETE_LABEL: "Are you sure you want to delete this Buyer?",
  ADDRESS_DELETE_LABEL: "Are you sure you want to delete this Address?",
  BUYER_EDIT_LABEL: "Update Buyer",
  BUYER_NAME: "Buyer Name",
  MOBILE_NO_LABEL: "Mobile Number",
  DELETE_SINGLE_PRODUCT: "deleteSingle",
  DELETE_ALL_PRODUCT: "deleteAll",
  ORDEREDIT: "orderEdit",
  BUYER_ORDER: "buyerOrder",
  ORDER_INFO: "ordersInfo",
  BUYERS_INFO: "BuyerInfo",
  OLD_PASSWORD_LABEL: "Old Password",
  NEW_PASSWORD_LABEL: "New Password",
  UPDATE_PASSWORD_LABEL: "Change Your Password Here...",
  BLOCK_BUYER_MSG: "Are you sure you want to Block this Buyer?",
  INACTIVE_BUYER_MSG: "Are you sure you want to Inactive this Buyer?",
  ACCEPETED_BUYER_MSG: "Are you sure you want to Accepted this Buyer?",
  ACTIVE_BUYER_MSG: "Are you sure you want to Active this Buyer?",
  ADDRESS_NAME_LABEL: "Address Name",
  ADDRESS_LINE_LABEL: "Address Line",
  REJECT_BUYER_MSG: "Are you sure you want to Reject this Buyer?",
  ACCEPET_BUYER_MSG: "Are you sure you want to Accept this Buyer?",
  ADD_ADDRESS: "Add Address",
  UPDATE_ADDRESS: "Update Address",
  LOGIN_ACCOUNT: "Already have an account yet?",
  LOGIN_ACCOUNT_LINK: " Login",
  REGISTRATION_CINFIEM_LABEL: "Go Back To Login",
  VIEW_ORDER_STATUS: "Status",
  EDIT_ORDER_STATUS_MODAL: "Edite Order Status",
  GST: "GST",
  PAN: "PAN",
  GST_NO_LABEL: "GST No",
  PAN_NO_LABEL: "PAN No",
  IMPORT: "Import",
  MARK_AS_NOT_AVAILABLE: "Mark as not Available",
  MARK_AS_AVAILABLE: "Mark as Available",
  SELECT_CSV_FILE: "Select CSV File",
  IMPORT_CATEGORY: "Import Category",
  IMPORT_PRODUCT: "Import Product",
  PRODUCT_SUMMARY: "Product Summary",

  // Button
  REGISTER: "Register",
  RESEND: "Resend Email",
  OK: "OK",
  LOGOUT: "Log out",
  EDIT: "Edit",
  VIEW: "View",
  BLOCKED: "Blocked",
  INACTIVE: "Inactive",
  ACTIVE: "Active",
  ACCEPTED: "Accepted",
  DELETE: "Delete",
  CANCEL: "Cancel",
  ADD_CATEGORY: "Add Category",
  UPDATE_CATEGORY: "Update Category",
  DELETE_SELECTED_PRODUCT: "Delete",
  CREATE_NEW_PRODUCT: "Create new Product",
  CREATE_NEW_BUYER: "Add Buyer",
  CREATE_NEW_ORDER: "Add Order",
  BUYER_REQUEST: "Buyer Request",
  BUYER_ADD_MESSAGE: "Go Back to Buyers",
  ACCEPT: "Accept",
  REJECT: "Reject",
  CREATE_ORDER: "Create Order",
  BACK_BUTTON: "Back",
  NEXT_BUTTON: "Next",
  INVOICE: "Receipt",
  DONE: "Done",
  PENDING: "Pending",
  DELIVERED: "Delivered",
  UPDATE_PROFILE: "Update Profile",
  DELETE_UPPERCASE: "DELETE",
  EDIT_UPPERCASE: "EDIT",
  VIEW_UPPERCASE: "VIEW",
  BLOCKED_UPPERCASE: "BLOCKED",
  INACTIVE_UPPERCASE: "INACTIVE",
  ACTIVE_UPPERCASE: "ACTIVE",
  ACCEPTED_UPPERCASE: "ACCEPTED",
  CHANGE_PASSWORD: "Change Password",
  SEND_LINK: "Send Link",
  UPDATE_PASSWORD: "Update Password",
  DOWNLOAD: "Download",
  SEND: "Send",
  YES: "Yes",
  NO: "No",
  PARTIAL_DELIVERED: "Partial Delivered",
  CANCELLED: "Cancelled",

  //delete model label
  DELTE_PRODUCT: "Delete Product",
  DELETE_ORDER: "Delete Order",
  DELETE_CATEGORY: "Delete Category",
  DELETE_BUYER: "Delete Buyer",

  //Orders Module
  ORDERS_LIST_HEADER: "Orders List",
  TOTAL_ORDERS_FOUND: "Orders on Screen",
  ADD_ORDER_LABEL: "Add Order",
  UPDATE_ORDER_LABEL: "Update Order",
  SELECT_PRODUCT_LABEL: "Product Name",
  PRODUCT_PLACEHOLDER: "Select Product",
  BUYERS_COMPANY: "Buyer's Company",
  BUYERS_COMPANY_PLACEHOLDER: "Select Buyer's Company",
  NOTES_LABEL: "Notes",
  BUYER_LABEL: "Buyer",
  ORDER_DATE_LABEL: "Order Date",
  SCHEDULRD_DATE_DATE: "Scheduled Delivery Date",
  CONTACT_DETAILS: "Contact Details",
  NOTES: "Enter Notes",
  APPROX_DELIVERY_DATE: "Approx Delivery Date",
  DELIVERY_ADDRESS: "Delivery Address",
  BILLING_ADDRESS: "Billing Address",
  ORDER_CONFIRMATION: "Order Confirmation",
  BUYER_COMPANY_NAME: "Buyer Company Name",
  QUANTITY: "Quantity",
  ORDER_DELETE_LABEL: "Are you sure you want to delete this Order?",
  ORDERS: "Orders",
  ORDER_DETAILS: "Order Details",
  CUSTOMER_DETAILS: "Customer Details",
  TOTAL_AMOUNT: "Total Amount",
  TOTAL_AMOUNT_HC: "TOTAL AMOUNT",
  ORDERED_ITEMSLIST: "Ordered Items",
  ORDER: "Order",
  CLEAR: "CLEAR",
  TOTAL_ORDER: "Total Order",
  TOTAL_SHIPPED: "Total Shipped",
  TO_BE_DELIVERED: "To Be Delivered",
  INVOICED: "Invoiced",
  TODAYS_MONEY: "Today’s Money",
  TO_BE_SHIPPED: "To Be Shipped",
  QTY: "Qty",
  IN_PROCCESS: "In Proccess",
  TOTAL_ORDERS: "Total Orders",
  TOTAL_SHIPPE: "Total Shipped",
  TOTAL_DELIVERED: "Total Delivered",
  REJECTED_CATEGORY: "Rejected Category",
  REJECTED_PRODUCT: "Rejected Product",
  TOTAL_PENDING: "Total Pending",
  TOTAL_PARTIAL_DELIVERED: "Total Partial Delivered ",
  TOTAL_CANCELLED: "Total Cancelled",
  TOTAL_INPROCESS: "Total In Proccess",
  SHIPPING_CHARGE_VALUE: "₹ 0",

  // Steper's Step
  STEP_1: "Step 1",
  STEP_2: "Step 2",
  STEP_3: "Step 3",
  STEP_4: "Step 4",

  // Profile Module
  USERNAME_REQ: "Name is Required",
  PHONE_NO_REQ: "Phone No is Required",
  JOINED_DATE_LABEL: "Joined Date",
  COMPANY_CODE_LABEL: "Company Code",

  //Column HeaderCell
  CATEGORY_HC: "Category Name",
  CATEGORY_HC_CODE: " Category Code",
  EDIT_HC: "EDIT",
  IMAGE_HC: "Image",
  NAME_HC: "Name",
  UNIT_HC: "Unit",
  QUT_RANGE_HC: "Quantity Range",
  DESCRIPTION_HC: "Description",
  PRICE_HC: "Price",
  AVAILABLE_HC: "Available",
  REASON_HC: "Reason",
  COMPANY_NAME_HC: "Company Name",
  BUYER_NAME_HC: "Buyer Name",
  CONTACT_HC: "Contact",
  STATUS_HC: "Status",
  ORDER_NUMBER_HC: "Order Number",
  ORDER_DATE_HC: "Order Date",
  ORDER_VALUE_HC: "Order Value",
  ITEMS_HC: "Items",
  PPODUCT_NAME_HC: "Product Name",
  CATEGORY_ORDERINFO_HC: "Category",
  QUANTITY_HC: "Quantity",
  TOTAL_PRICE_HC: "Total Price",
  DELIVERED_DATE_HC: "Deliver Date",
  VERIFY_BUYER: "Verify Buyer",
  PHONE_NO_HC: "Phone No",
  ADDRESS_NAME_HOC: "Address Name",
  ADDRESS_LINE_HOC: "Address Line",
  STATE_HOC: "State",
  CITY_HOC: "City",
  PINCODE_HOC: "Pincode",
  SKU_HOC: "Sku",

  //Dashboard
  DASHBOARD_TITLE: "Dashboard",
  ORDERS_TITLE: "Orders",
  REVENUE_TITLE: "Revenue",
  VOLUME_TOTLE: "Volume",
  TOTAL_BUYER: "Total Buyer",
  ORDER_TITLE: "Pending Order List",
  SELECT_FILTER: "Select Filter",
  SCHEDULE: "Schedule",
  TODAY: "Today",
  DAY_7s: "7 Day's",
  DAY_30s: "30 Day's",



  //invite user 
  DATE_TITLE: "Date",
  INVITING_SUPER_ADMIN_TITLE: "Inviting Super Admin",
  EMAIL_TITLE: "Email",

  //logout modal
  LOGOUT_TITLE: "Are you sure you want to Logout?",
  YES_BUTTON: "Yes",
  CANCEL_BUTTON: "Cancel",

  //user
  ENTER_EMAIL_TITLE: "Enter e-mail address of new user :",
  SEND_INVITE_BTN: "Send invitation",
  INVITE_USER_TITEL: "Invited user",
  CURRENT_USER_TITEL: "Current User",
  DELETE_USE_TITLE: "Are you sure you want to delete this user?",
  WITHDRAW_USER_TITLE: "Are you sure you want to withdraw this invitation?",
  RESEND_TITLE: "Are you sure you want to Resend invitation?",
  SCEEN_NAME_HEADING: "Screen Name",
  NONE_HEADING: "None",
  VIEW_HEADING: "view",
  VIEW_ADD_HEADING: "view & Add",
  VIEW_ADD_EDIT: "view & Add  & Edit",
  ADMIN_HEADING: "Admin",

  //error page
  ERROR_HEADING: "Internal server error",
  ERROR_DEC: "Oops! something went wrong",
};

export const Datakey = {
  COOKIE_NAME: "connect.sid",
  EDIT_CATEGORY: "editCategory",
  DELETE_CATEGORY: "DeleteCategory",
  EDIT_PRODUCT: "editProduct",
  SHOW_PRODUCT: "ShowProduct",
  DELETE_PRODUCT: "DeleteProduct",
  SHOW_BUYER: "ShowBuyer",
  DELETE_BUYER: "DeleteBuyer",
  EDIT_BUYER: "editBUYER",
  ACCEPT: "Accept",
  REJECT: "Reject",
  NAME: "categoryName",
  PRODUCT_NAME: "productName",
  ID: "id",
  IMAGE: "image",
  CATEGORY_NAME: "category.categoryName",
  CATEGORY_CODE: "categoryCode",
  UNIT: "unit",
  MIN_QUANTITY: "minOrderQuantity",
  MAX_QUANTITY: "maxOrderQuantity",
  DESCRIPTION: "description",
  PRICE: "price",
  PHONE: "phone",
  _ID: "_id",
  TOTAL_AMOUNT: "totalAmount",
  TOTAL_QUANTITY: "totalQuantity",
  TOTAL_ITEM: "itemCount",
  SHOW_ORDER: "ShowOrder",
  DELETE_ORDER: "DeleteOrder",
  EDIT_ORDER: "EditOrder",
  PRODUCT: "product",
  CATEGORY: "category",
  QUANTITY: "quantity",
  TOTAL_PRICE: "total",
  ISAvailable: "isAvailable",
  ISPublished: "isPublished",
  ADDRESS_NAME: "addressName",
  ADDRESS_LINE: "addressLine",
  STATE: "state",
  CITY: "city",
  PINCODE: "pincode",
  SKU: "sku",
  USER_EMAIL: "email"
};
